<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <div class="chat-main-box">
                    <div class="chat-right-aside" style="width:100%">
                        <div class="chat-main-header">
                        </div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <form class="form-horizontal form-material">
                                            Daftar Label
                                            <!-- <router-link :to="{ name: 'broadcast' , query: { tabs: 'add' } }" v-if="showPage == 'list'">
                                                <v-btn class="btn btn-primary pull-right text-white btn btn-primary">
                                                    Buat Broadcast
                                                </v-btn>
                                            </router-link> -->
                                            <div class="clearfix"></div>
                                            <br>
                                            <div class="clearfix"></div>
                                            <div class="wrapp-input-contact m-t-20">
                                                <template>
                                                    <div class="list-auto-template">
                                                        <v-row>
                                                            <v-col cols="6" class="col col-md-6">
                                                                <v-text-field v-model="term" :rules="rules.required" placeholder="Cari nama label"></v-text-field>
                                                            </v-col>
                                                           
                                                            <v-col cols="6" class="col col-md-3 pt-7">
                                                                <v-btn class="mr-2 text-white btn btn-primary" @click="handleSearch">
                                                                    Cari
                                                                </v-btn>
                                                                <v-btn @click="resetFilter">
                                                                    Reset
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>

                                                        <div class="my-5">
                                                            <v-data-table :headers="headers" :items="labelList"
                                                                :options.sync="options" :server-items-length="totalElements"
                                                                class="elevation-1" :loading="isLoading"
                                                                :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
                                                            >
                                                                <template v-slot:item.createdOn="{ item }">
                                                                    <span>{{ item.time }}</span>
                                                                </template>
                                                                <template v-slot:item.action="{ item }">
                                                                    <a @click="openDialog(item)">Lihat Detail</a>
                                                                </template>
                                                            </v-data-table>
                                                        </div>
                                                            
                                                        <b-modal id="modal-1" title="Riwayat penggabungan kontak">
                                                           <v-row>
                                                                <v-col cols="8" class="">
                                                                    <v-text-field :rules="rules" placeholder="kontak"></v-text-field>
                                                                </v-col>
                                                                <v-col cols="4" class="mt-3">
                                                                    <v-btn class="mr-2 text-white btn btn-primary">
                                                                        Cari
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>

                                                            <table class="mt-4 table table-bordered table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Nama Kontak</th>
                                                                        <th scope="col">Aksi</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Nenden</td>
                                                                        <td><a v-b-modal.modal-1>Lihat kontak</a></td>

                                                                    </tr>
                                                                      <tr>
                                                                        <td>Nenden</td>
                                                                        <td><a v-b-modal.modal-1>Lihat kontak</a></td>
                                                                    </tr>
                                                                      <tr>
                                                                        <td>Nenden</td>
                                                                         <td><a v-b-modal.modal-1>Lihat kontak</a></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-modal>

                                                        <b-modal id="modal-1" title="Riwayat penggabungan kontak">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Nama Akun<br>No.HP</th>
                                                                        <th scope="col">Tanggal<br>status</th>
                                                                        <th scope="col">Operator</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Nenden<br>0825645121</td>
                                                                        <td>16/02/2023<br>berhasil</td>
                                                                        <td>Rama</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-modal>
                                                    </div>
                                                </template>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <DetailLabelDialog ref="label" 
            :showDialog="dialog" 
            :labelName="selectedLabel.name"
            @close-dialog="closeDialog" />

        <SuccessNotif />

    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import {
        mapState
    } from 'vuex'

    import DetailLabelDialog from '../components/DetailLabelDialog.vue'

    // const $ = require("jquery")
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                broadcastCallback: state => state.socket.broadcastCallback,
            })
        },
        data: function () {
            return {
                totalElements: 0,

                term: String(),

                headers: [
                    { text: 'Nama', value: 'name', sortable: false },
                    { text: 'Tanggal Pembuatan', value: 'time', sortable: false },
                    { text: 'Jumlah Kontak', value: 'contactCount', sortable: false },
                    { text: 'Aksi', value: 'action', sortable: false },
                ],

                labelList: Array(),
                
                selectedLabel: Object(),
                options: Object(),

                dialog: false,
                isLoading: false,
                formValid: false,

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            options: {
                handler() {
                    this.loadLabel();
                },
                deep: true,
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
        },
        methods: {
            closeDialog: function() {
                this.selectedLabel = Object()
                this.dialog = false
            },
            openDialog: function(label)  {
                this.selectedLabel = label
                this.dialog = true
            },
            handleSearch: async function() {
                this.options.page = 1
                await this.loadLabel()
            },
            resetFilter: async function() {
                this.term = String()

                await this.loadLabel()
            },
            loadInitialData: async function () {
                this.loadLabel()
            },
            loadLabel: async function() {
                this.isLoading = true
                let params = Object();

                params.term = this.term
                if (this.options && this.options.page)  params.page = this.options.page;
                if (this.options && this.options.itemsPerPage) params.size = this.options.itemsPerPage;

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/label/list', {
                        params
                    })

                    this.labelList = data.content.map(e => {
                        e.time = moment(e.createdOn).locale('id').format('l')
                        return e
                    })
                    
                    this.totalElements = data.page.totalElements
                } catch (error) {
                    console.log("load-broadcast", error);
                }
                this.isLoading = false
            },
            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },
            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },
            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },
        created: function () {
            this.$emit('update:layout', MainLayout);
        },
        mounted: function () {
            Scrollbar.initAll();
            // if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
            //     this.loadInitialData();
            // }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            // ErrorMessage,
            SuccessNotif,
            DetailLabelDialog,
        }
    }
</script>