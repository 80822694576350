<template>
    <div class="row">
        <div class="col-12">
            <div class="card card m-b-0">
                <div class="chat-main-box">
                    <div class="chat-right-aside" style="width:100%">
                        <div class="chat-main-header">
                        </div>
                        <div class="chat-rbox static-content">
                            <div class="wrapp-chat-content" data-scrollbar="true" tabindex="1"
                                style="overflow: hidden; outline: none;">
                                <div class="scroll-content">
                                    <div class="contact-add">
                                        <form class="form-horizontal form-material">
                                            Pembatalan Pesanan
                                            <div class="clearfix"></div>
                                            <br>
                                            <div class="clearfix"></div>
                                            <div class="wrapp-input-contact m-t-20">
                                                <template>
                                                    <div class="list-auto-template">
                                                        <v-form v-model="filterValid">
                                                            <v-row>
                                                                <v-col cols="6" class="col col-md-3">
                                                                    <v-menu ref="month" v-model="isOpenMonth" :close-on-content-click="false" :nudge-right="40"
                                                                        :return-value.sync="selectedMonth" transition="scale-transition" min-width="290px" offset-y>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-text-field :value="selectedMonth ? utcToLocal(selectedMonth,'MMMM-YYYY') : ''"
                                                                                class="" label="Bulan & Tahun" readonly hide-details v-on="on" :rules="rules.required" />
                                                                        </template>
                                                                        <v-date-picker type="month" v-model="month" no-title scrollable
                                                                            @input="$refs.month.save(month)" />
                                                                    </v-menu>
                                                                </v-col>
                                                                <v-col cols="6" class="col col-md-3 mt-1 mb-0-mobile pb-0-mobile">
                                                                    <v-combobox hide-selected persistent-hint small-chips multiple
                                                                        v-model="selectedCustomerRoles" 
                                                                        :loading="isLoadingCustomerRoles" 
                                                                        :items="customerRoles"
                                                                        :search-input.sync="search" 
                                                                        :rules="rules.required"
                                                                        item-value="id"
                                                                        item-text="name"
                                                                        label="Jenis Pelanggan">
                                                                        <template v-slot:no-data>
                                                                            <v-list-item>
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title>
                                                                                        Tidak ditemukan "<strong>{{ search }}</strong>".
                                                                                    </v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </template>
                                                                        <template v-slot:selection="{ item }">
                                                                            <div class="mr-1">
                                                                                <span>{{ item.name }}</span>
                                                                                <v-icon small @click="deleteCustRole(item)">$delete</v-icon>
                                                                            </div>
                                                                        </template>
                                                                        <template v-slot:item="{ item }">
                                                                            {{ item.name }}
                                                                        </template>
                                                                    </v-combobox>
                                                                </v-col>
                                                                <v-col cols="12" class="col col-md-3 mb-6 pt-0-mobile mt-0 mobile">
                                                                    <div id="scrollTop" class="mt-2 mt-0-mobile">
                                                                        <v-btn depressed normal class="mr-2" color="deep-purple accent-4"
                                                                        :disabled="!filterValid || isLoadingTable" @click="handleSearch">
                                                                            <span class="text-white">
                                                                                Cari
                                                                            </span>
                                                                        </v-btn>
                                                                        <v-btn depressed normal color="light" @click="resetParams">
                                                                            Reset
                                                                        </v-btn>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-form>
                                                        <v-row v-if="orderList.length > 0 && selectedFields.length > 0 && !isLoadingTable">
                                                            <v-col>
                                                                <v-menu offset-y>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn color="deep-purple accent-4" dark v-bind="attrs" v-on="on">
                                                                            <span class="text-white">
                                                                                Kirim Pesan Batch
                                                                            </span>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-list dense>
                                                                        <v-list-item-group>
                                                                            <v-list-item @click="openPreviewBatch('WA')"> 
                                                                                <v-list-item-icon>
                                                                                    <v-icon>mdi-whatsapp</v-icon>
                                                                                </v-list-item-icon>
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title>WhatsApp</v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                            <v-list-item @click="openPreviewBatch('EMAIL')">
                                                                                <v-list-item-icon>
                                                                                    <v-icon>mdi-gmail</v-icon>
                                                                                </v-list-item-icon>
                                                                                <v-list-item-content>
                                                                                    <v-list-item-title>Email</v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </v-list-item-group>
                                                                    </v-list>
                                                                </v-menu>
                                                            </v-col>
                                                        </v-row>
                                                        <v-progress-linear v-if="isLoadingTable"
                                                            indeterminate 
                                                            color="primary"
                                                        ></v-progress-linear>
                                                        <v-row>
                                                            <v-col>
                                                                <h6 class="text-danger" v-if="orderList.length > 0">
                                                                    *Harga Sebelum Diskon
                                                                </h6>
                                                                <v-simple-table class="elevation-1 row-top table-striped table-condensed order-report">
                                                                <template v-slot:default>
                                                                    <thead>
                                                                        <tr>
                                                                            <th rowspan="2" v-if="orderList.length > 0">
                                                                                <v-checkbox v-model="checked" @click="clickChecked()"></v-checkbox>
                                                                            </th>
                                                                            <th rowspan="2" @click="sortTable('customer_name', sort.customer_name)">
                                                                                ID 
                                                                                <br />
                                                                                Nama
                                                                                <br />
                                                                                Jenis Pelanggan
                                                                            </th>
                                                                            <th class="text-center" rowspan="2">Notif</th>
                                                                            <th class="text-center" colspan="2">Bulan Sebelumnya</th>
                                                                            <!-- <th class="text-center" colspan="3">Total Pembatalan Pemesanan</th> -->
                                                                            <th class="text-center" colspan="3">Total Pembatalan Nota</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th class="text-center" @click="sortTable('prev_order_amount', sort.prev_order_amount)">
                                                                                Total Pemesanan
                                                                            </th>
                                                                            <th class="text-center" @click="sortTable('prev_return_amount', sort.prev_return_amount)">
                                                                                Total Pengembalian
                                                                            </th>
                                                                            <!-- <th class="text-center" @click="sortTable('canceled_booking_item_qty', sort.canceled_booking_item_qty)">
                                                                                Jumlah
                                                                            </th>
                                                                            <th class="text-center" @click="sortTable('canceled_booking_amount', sort.canceled_booking_amount)">
                                                                                Nominal
                                                                            </th>
                                                                            <th class="text-center">Persentase</th> -->
                                                                            <th class="text-center" @click="sortTable('canceled_order_item_qty', sort.canceled_order_item_qty)">
                                                                                Jumlah
                                                                            </th>
                                                                            <th class="text-center" @click="sortTable('canceled_order_amount', sort.canceled_order_amount)">
                                                                                Nominal
                                                                            </th>
                                                                            <th class="text-center">Persentase</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="order in orderList" :key="order.customerId">
                                                                            <td>
                                                                                <v-checkbox v-model="selectedFields" :value="order" class="mt-0 pt-0"></v-checkbox>
                                                                            </td>
                                                                            <td>
                                                                                {{ order.customerId }} 
                                                                                <br />
                                                                                {{ order.customerName }}
                                                                                <br />
                                                                                {{ order.roleId }}
                                                                            </td>
                                                                            <td>
                                                                                <v-btn icon color="success" 
                                                                                    @click="openPreview(order, 'WA')">
                                                                                    <v-icon>mdi-whatsapp</v-icon>
                                                                                </v-btn>
                                                                                <v-btn icon color="red" 
                                                                                    @click="openPreview(order, 'EMAIL')">
                                                                                    <v-icon>mdi-gmail</v-icon>
                                                                                </v-btn>
                                                                            </td>
                                                                            <td class="text-center">Rp {{ formatCurrency(order.prevOrderAmount) }}</td>
                                                                            <td class="text-center">Rp {{ formatCurrency(order.prevReturnAmount) }}</td>
                                                                            <!-- <td class="text-center">{{ order.canceledBookingItemQty }}</td> -->
                                                                            <!-- <td class="text-center">Rp {{ formatCurrency(order.canceledBookingAmount) }}</td> -->
                                                                            <!-- <td class="text-center">{{ order.pctCancelBooking ? order.pctCancelBooking : 0 }}%</td> -->
                                                                            <td class="text-center">{{ order.canceledOrderItemQty }}</td>
                                                                            <td class="text-center">Rp {{ formatCurrency(order.canceledOrderAmount) }}</td>
                                                                            <td class="text-center">{{ order.pctCancelOrder ? order.pctCancelOrder : 0 }}%</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                                </v-simple-table>
                                                            </v-col>
                                                        </v-row>
                                                        <v-progress-linear v-if="isLoadingTable"
                                                            indeterminate 
                                                            color="primary"
                                                        ></v-progress-linear>
                                                        <v-row class="text-center mb-5" v-if="orderList.length > 0">
                                                            <v-col>
                                                                <v-pagination
                                                                    v-model="number"
                                                                    :length="totalPages"
                                                                    :total-visible="5"
                                                                    prev-icon="mdi-menu-left"
                                                                    next-icon="mdi-menu-right"
                                                                ></v-pagination>
                                                                <v-btn color="primary mt-2" @click="showAll()">
                                                                    Tampilkan Semua
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </div>
                                                </template>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-x" style="display: none;">
                                    <div class="scrollbar-thumb scrollbar-thumb-x"
                                        style="width: 661px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                                <div class="scrollbar-track scrollbar-track-y" style="display: block;">
                                    <div class="scrollbar-thumb scrollbar-thumb-y"
                                        style="height: 445.289px; transform: translate3d(0px, 0px, 0px);"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-dialog max-width="600" v-model="dialog" persistent>
            <template>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-icon class="mr-5">
                            {{ kind == 'WA' ? 'mdi-whatsapp' : 'mdi-gmail' }}
                        </v-icon>
                        <v-toolbar-title>
                            <span>
                                Kirim Pesan Ke {{ kind == 'WA' ? 'WhatsApp' : 'Email' }}
                            </span>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row class="mt-2" v-if="isLoadingDialog">
                            <v-col cols="12" class="text-center">
                                <v-progress-circular 
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2" v-else>
                            <v-col cols="12">
                                <v-textarea outlined
                                    label="Pesan"
                                    v-model="text"
                                    rows="8"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" v-if="kind == 'WA'">
                                <v-autocomplete
                                    v-model="selectedInputContactId"
                                    class="" :loading="isLoadingContact"
                                    :items="inputContactList"
                                    :search-input.sync="searchInputContact"
                                    :no-filter="true"
                                    label="Kontak"
                                    hide-no-data
                                    item-value="id"
                                    @keyup.enter="searchContact"
                                    :rules="rules.required"
                                    append-outer-icon="*">
                                    <template v-slot:selection="data">
                                        <span class="ml-2">
                                            {{ data.item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data" >
                                        <div class="d-block" style="width:100%">
                                        ({{ data.item.name }})
                                        {{ data.item.primaryPhoneNumber }}
                                        <span class="pull-right text-right">
                                            <v-icon v-if="data.item.contactStatus == 'ACTIVE'" title="Channel Aktif" color="green" small>mdi-check-circle</v-icon>
                                            <v-icon v-if="data.item.contactStatus == 'INACTIVE'" title="Channel Inaktif" color="red" small>mdi-minus-circle</v-icon>
                                        </span>
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="kind == 'WA'">
                                <v-autocomplete
                                    v-model="selectedInputChannel"
                                    class="" :loading="isLoadingChannel"
                                    :items="inputChannelList"
                                    item-value="_id" :no-filter="true"
                                    hide-no-data
                                    label="Saluran"
                                    :rules="rules.required"
                                    append-outer-icon="*">
                                    <template v-slot:selection="data">
                                        <span class="ml-2">
                                            {{ data.item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <div class="d-block" style="width:100%">
                                            {{ data.item.name }}
                                            <span class="pull-right text-right">
                                                <v-icon v-if="data.item.status == 'ACTIVE'" title="Channel Aktif" color="green" small>mdi-check-circle</v-icon>
                                                <v-icon v-if="data.item.status == 'INACTIVE'" title="Channel Inaktif" color="red" small>mdi-minus-circle</v-icon>
                                            </span>
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="kind == 'EMAIL'">
                                <v-simple-table class="elevation-1 row-top table-striped table-condensed">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Kontak</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ selectedCustomer.name }}</td>
                                                <td>{{ selectedCustomer.primaryEmail }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn @click="dialog = false">
                            BATAL
                        </v-btn>
                        <v-btn @click="sendMessage()" color="primary" :loading="isLoadingSend" v-if="kind == 'WA'">
                            KIRIM
                        </v-btn>
                        <v-btn @click="sendEmail()" color="primary" :loading="isLoadingSend" v-if="kind == 'EMAIL'">
                            KIRIM
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-dialog max-width="600" v-model="dialogBatch" persistent>
            <template>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-icon class="mr-5">
                            {{ kind == 'WA' ? 'mdi-whatsapp' : 'mdi-gmail' }}
                        </v-icon>
                        <v-toolbar-title>
                            <span>Kirim Pesan Ke {{ kind == 'WA' ? 'WhatsApp ' : 'Email ' }} Batch</span>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row class="mt-2" v-if="isLoadingDialog">
                            <v-col cols="12">
                                <v-progress-circular 
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2" v-else>
                            <v-col cols="12">
                                <v-simple-table class="elevation-1 row-top table-striped table-condensed order-report">
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span>ID</span>
                                                    <br />
                                                    <span>Nama</span>
                                                    <br />
                                                    <span>Jenis Pelanggan</span>
                                                </th>
                                                <th>Kontak</th>
                                                <th class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="selected in selectedFields" :key="selected.key">
                                                <td>
                                                    <span>{{ selected.customerId }}</span>
                                                    <br />
                                                    <span>{{ selected.customerName }}</span>
                                                    <br />
                                                    <span>{{ selected.roleId }}</span>
                                                </td>
                                                <td>
                                                    <div v-if="selected.customer">
                                                        <span>{{ selected.customer.primaryPhoneNumber }}</span>
                                                        <br />
                                                        <span>{{ selected.customer.primaryEmail }}</span>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <v-progress-circular v-if="selected.isLoading == true"
                                                        color="primary"
                                                        indeterminate
                                                    ></v-progress-circular>
                                                    <v-icon color="green" v-else-if="selected.isSuccess == true">
                                                        mdi-check
                                                    </v-icon>
                                                    <v-icon color="red" v-else-if="selected.isSuccess == false">
                                                        mdi-window-close
                                                    </v-icon>
                                                    <span v-else-if="selected.haveNoOrder == true" class="text-success">
                                                        tidak memiliki nota yang belum di bayar
                                                    </span>
                                                </td>
                                                
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete v-if="kind == 'WA'"
                                    v-model="selectedInputChannel"
                                    class="" :loading="isLoadingChannel"
                                    :items="inputChannelList"
                                    item-value="_id" :no-filter="true"
                                    hide-no-data
                                    label="Saluran"
                                    :rules="rules.required"
                                    append-outer-icon="*">
                                    <template v-slot:selection="data">
                                        <span class="ml-2">
                                            {{ data.item.name }}
                                        </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <div class="d-block" style="width:100%">
                                            {{ data.item.name }}
                                            <span class="pull-right text-right">
                                                <v-icon v-if="data.item.status == 'ACTIVE'" title="Channel Aktif" color="green" small>mdi-check-circle</v-icon>
                                                <v-icon v-if="data.item.status == 'INACTIVE'" title="Channel Inaktif" color="red" small>mdi-minus-circle</v-icon>
                                            </span>
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn @click="dialogBatch = false">
                            TUTUP
                        </v-btn>
                        <v-btn @click="sendMessageBatch()" color="primary" :loading="isLoadingSend" v-if="kind == 'WA'">
                            KIRIM
                        </v-btn>
                        <v-btn @click="sendEmailBatch()" color="primary" :loading="isLoadingSend" v-if="kind == 'EMAIL'">
                            KIRIM
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <SuccessNotif />

    </div>
</template>
<script>
    import axios from 'axios'
    import Scrollbar from 'smooth-scrollbar'
    // import ErrorMessage from '../components/ErrorMessage'
    import MainLayout from "../layouts/MainLayout"
    import SuccessNotif from "../components/SuccessNotif"
    import {
        mapState
    } from 'vuex'
    import Toast from '../plugins/vuenotification'

    // const $ = require("jquery")
    const {
        commonpagesetup,
        mainuisetup
    } = require('../utils/ui-setup')
    var moment = require('moment');

    const template = require('lodash.template');

    const uuidv4 = require('uuid/v4');

    export default {
        computed: {
            ...mapState({
                sessionInfo: state => state.session.sessionInfo,
                broadcastCallback: state => state.socket.broadcastCallback,
            })
        },
        data: function () {
            return {
                number: 1,
                size: 10,
                totalPages: 0,
                totalElements: 0,

                session: String(),
                search: String(),
                selectedMonth: String(),
                month: String(),
                selectedCustomerRole: String(),
                selectedCustomerId: String(),
                searchCustomerRoles: String(),
                selectedSort: 'customer_name,asc',
                currentRoleId: String(),
                text: String(),
                selectedInputChannel: String(),
                selectedInputContactId: String(),
                searchInputContact: String(),
                kind: 'WA',
            
                selectedCustomerRoles: Array(),
                selectedFields: Array(),
                customerRoles: Array(),
                orderList: Array(),
                waChannels: Array(),
                inputChannelList: Array(),
                inputContactList: Array(),

                selectedCustomer: Object(),

                isOpenMonth: false,
                isLoadingTable: false,
                isLoadingCustomerRoles: false,
                filterValid: false,
                checked: false,
                dialog: false,
                dialogBatch: false,
                formRoleValid: false,
                isLoadingPutCustomerRole: false,
                isLoadingBtn: false,
                isLoadingWa: false,
                isLoadingChannel: false,
                isLoadingContact: false,
                isLoadingSend: false,
                isLoadingDialog: false,

                sort: {
                    customer_name: "asc",
                    prev_order_amount: "asc",
                    prev_return_amount: "asc",
                    canceled_booking_item_qty: "asc",
                    canceled_booking_amount: "asc",
                    canceled_order_item_qty: "asc",
                    canceled_order_amount: "asc",
                },

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                },
            }
        },
        watch: {
            searchCustomerRoles(val) {
                if (val && (val.length === 0 || val.length > 2)) this.searchCustomerRoleWithDelay(val);
            },
            selectedFields(val) {
                if(val.length != 0 && val.length == this.orderList.length) {
                    this.checked = true
                } else {
                    this.checked = false
                }
            },
            number() {
                this.orderReport(false)
            },
            sessionInfo: function (val) {
                if (!this.$helpers.isBlank(val) && val.isLoggedIn) {
                    this.loadInitialData();
                }
            },
        },
        methods: {
            clickChecked() {
                if(this.checked) {
                    this.selectedFields = this.orderList
                } else {
                    this.selectedFields = Array()
                }
            },

            deleteCustRole: function(role) {
				this.selectedCustomerRoles = this.selectedCustomerRoles.filter(e => e.id != role.id)
			},

            showAll: function() {
                this.size = this.totalElements
                this.number = 1
                this.orderReport(false)
            },

            async sortTable(sortField, sortDir) {
                const dir = sortDir == 'asc' ? 'desc' : 'asc'
                this.selectedSort = sortField + ',' + dir

                const sort = { ...this.sort }
                sort[sortField] = dir
                this.sort = sort

                await this.orderReport(true)
            },

            sendMessageBatch: async function() {
                this.isLoadingSend = true

                for (let i = 0; i < this.selectedFields.length; i++) {
                    const e = this.selectedFields[i];

                    try {
                        e.isLoading = true

                        // const { data: customer } = await this.getCustomerById(e.customerId)
                        
                        const data = await this.getListOrderByCustomer(e)

                        if(data.content.length > 0) {
                            const body = {
                                id: uuidv4(),
                                channelId: this.selectedInputChannel,
                                text: this.formatMessage(e, data),
                                number: e.customer.primaryPhoneNumber
                            };
    
                            const res = await this.postMessage(body, e.customer.communicatorDefaultChannel, e.customer.id)
    
                            if(res.status == 200) {
                                e.isSuccess = true
                            } else {
                                e.isSuccess = false
                            }
                        } else {
                            e.haveNoOrder = true
                        }

                        e.isLoading = false
                    } catch (error) {
                        e.isLoading = false
                        e.isSuccess = false
                        console.log("send-message-batch-error", error)
                    }
                }

                this.isLoadingSend = false
            },

            sendEmailBatch: async function() {
                this.isLoadingSend = true

                for (let i = 0; i < this.selectedFields.length; i++) {
                    const e = this.selectedFields[i];

                    try {
                        e.isLoading = true

                        const data = await this.getListOrderByCustomer(e)

                        if(data.content.length > 0) {
                            const body = {
                                to: e.customer.primaryEmail,
                                subject: "Informasi nota belum di bayar",
                                text: this.formatMessage(e, data)
                            }
    
                            const res = await this.postEmail(body)
    
                            if(res.status == 200) {
                                e.isSuccess = true
                            } else {
                                e.isSuccess = false
                            }
                        } else {
                            e.haveNoOrder = true
                        }

                        e.isLoading = false
                    } catch (error) {
                        e.isLoading = false
                        e.isSuccess = false
                        console.log("send-message-batch-error", error)
                    }
                }

                this.isLoadingSend = false
            },

            sendMessage: async function() {
                try {
                    this.isLoadingSend = true

                    const customer = this.inputContactList.find(e => e.id == this.selectedInputContactId)
                    const channel = this.inputChannelList.find(e => e._id == this.selectedInputChannel)
                    const defaultChannel = channel.channel_type == 'FBMSGR' ? 'FB' : channel.channel_type == 'INSTAGRAM' ? 'IG' : 'WA'

                    const body = {
                        id: uuidv4(),
                        channelId: this.selectedInputChannel,
                        text: this.text,
                        number: customer.primaryPhoneNumber
                    };

                    const res = await this.postMessage(body, defaultChannel, this.selectedInputContactId)

                    if(res.status == 200) {
                        this.dialog = false
                        Toast.showToast("success", "Berhasil mengirim pesan kepada " + customer.name)
                    }

                    this.isLoadingSend = false
                } catch (error) {
                    this.isLoadingSend = false
                    console.log("send-message-error", error)
                }
            },

            sendEmail: async function() {
                try {
                    this.isLoadingSend = true

                    const customer = this.selectedCustomer

                    const body = {
                        to: this.selectedCustomer.primaryEmail,
                        subject: "Informasi nota belum di bayar",
                        text: this.text
                    }

                    const res = await this.postEmail(body)

                    if(res.status == 200) {
                        this.dialog = false
                        Toast.showToast("success", "Berhasil mengirim email kepada " + customer.name)
                    }

                    this.isLoadingSend = false
                } catch (error) {
                    this.isLoadingSend = false
                    console.log("send-message-error", error)
                }
            },

            postMessage: async function(body, defaultChannel, contactId) {  
                try {
                    return await axios.post(process.env.VUE_APP_API_URL + '/message/' + contactId + '/' + defaultChannel, body);
                } catch (error) {
                    console.log("post-message-error", error)
                }
            },

            postEmail: async function(body) {
                try {
                    return await axios.post(process.env.VUE_APP_API_URL + `/email/send`, body)
                } catch (error) {
                    console.log("post-email-error", error)
                }
            },

            getCustomerById: async function(id) {
                try {
                    return await axios.get(process.env.VUE_APP_API_URL + '/contact/id/' + encodeURIComponent(id));
                } catch (error) {
                    console.log("get-customer-by-id-error", error)
                }
            },

            openPreviewBatch: async function(kind) {
                this.dialogBatch = true
                this.isLoadingDialog = true
                this.kind = kind

                for (let i = 0; i < this.selectedFields.length; i++) {
                    const e = this.selectedFields[i];

                    const { data: customer } = await this.getCustomerById(e.customerId)
                    const email = customer.emails.find(e => e.primary == true)
                    
                    if(email) customer.primaryEmail = email.email
                    
                    e.customer = customer
                }

                this.isLoadingDialog = false
            },

            openPreview: async function(order, kind) {
                this.isLoadingDialog = true
                this.kind = kind
                this.dialog = true

                const data = await this.getListOrderByCustomer(order)

                if(data.content.length > 0) {
                    this.searchInputContact = order.customerName
                    this.selectedInputContactId = order.customerId
    
                    if(kind == 'WA') {
                        this.searchContact()
                    } else {
                        const { data: customer } = await this.getCustomerById(order.customerId)
                        const email = customer.emails.find(e => e.primary == true)
                        
                        if(email) customer.primaryEmail = email.email
    
                        this.selectedCustomer = customer
                    }
                    this.text = this.formatMessage(order, data)
                } else {
                    Toast.showToast("success", order.customerName + " tidak memiliki nota yang belum di bayar")

                    this.dialog = false
                }

                this.isLoadingDialog = false
            },

            getListOrderByCustomer: async function(order) {
                try {
                    const selectedDate = moment(this.selectedMonth);

                    const startOfMonth = selectedDate.startOf('month').format('YYYY-MM-DD');
                    const endOfMonth   = selectedDate.endOf('month').format('YYYY-MM-DD');

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/sales-order', {
                        params: {
                            formalIds: this.searchTerm,
                            dateType: 'creationTime',
                            status: 'BOOKED',
                            endDate: endOfMonth,
                            startDate: startOfMonth,
                            paymentStatus: String(),
                            shipmentStatus: String(),
                            customerId: order.customerId,
                            page: 0,
                            size: 50,
                            sort: 'modificationTime,DESC',
                        }
                    })

                    return data
                } catch (error) {
                    console.log("get-order", error)
                }
            },

            handleSearch: async function() {
                this.session = String()

                this.number = 1
                this.size = 10
                await this.orderReport()
            },

            orderReport: async function(session) {
                try {
                    this.isLoadingTable = true
                    const splittedMonth = this.selectedMonth.split("-")

                    const config = Object()
                    config["params"] = Object()
                    config["params"]["month"] = splittedMonth[1]
                    config["params"]["year"] = splittedMonth[0]
                    config["params"]["sort"] = this.selectedSort    
                    config["params"]["size"] = this.size
                    config["params"]["page"] = this.number - 1
                    config["params"]["customerRoleIds"] = this.selectedCustomerRoles.map(e => e.id).join(",")

                    if(session) config["params"]["sessionId"] = this.session

                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cmrc/report-cancel-order', config)

                    this.orderList = data.data.content.map(e => {
                        e.isSuccess = null
                        e.isLoading = false
                        e.haveNoOrder = false

                        return e
                    })
                    this.number = data.data.number + 1
                    this.totalPages = data.data.totalPages
                    this.totalElements = data.data.totalElements
                    this.session = data.sessionId
                    this.selectedFields = Array()
                    this.isLoadingTable = false
                } catch (e) {
                    this.isLoadingTable = false

                    console.log("list-order:get", e.response);
                }
            },

            searchContact: async function () {
                this.isLoadingContact = true
                try {
                    var {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/contact/', {
                        params: {
                            page: 1,
                            term: this.searchInputContact,
                            size: 25,
                            status: 'ACTIVE',
                        }
                    });

                    this.inputContactList = data.content
                } catch (error) {
                    console.log("search-input-contact", error);
                }
                this.isLoadingContact = false
            },

            searchChannel: async function() {
                this.isLoadingChannel = true

                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/', {
                        params: {
                            page: 1,
                            size: 100
                            // term: this.searchInputChannel,
                        }
                    });

                    this.inputChannelList = data.content
                } catch (error) {
                    console.log("search-input-channel", error);
                }

                this.isLoadingChannel = false
            },

            fetchCustomerRoles: async function(val) {
                this.isLoadingCustomerRoles = true

                const config = new Object();
                config["params"] = new Object();
                config["params"]["size"] = 50;

                if (val) config["params"]["term"] = val

                try {
                    const res = await axios.get(process.env.VUE_APP_API_URL + `/cmrc/customer-roles`, config);
                    this.customerRoles = res.data.content;
                    this.isLoadingCustomerRoles = false
                } catch (e) {
                    this.isLoadingCustomerRoles = false
                    console.log("customer-roles:get", e)
                }
            },

            searchCustomerRoleWithDelay(val) {
                clearTimeout(this._timerId);
                this._timerId = setTimeout(() => this.fetchCustomerRoles(val), 500);
            },

            loadInitialData: async function() {
                await this.fetchCustomerRoles();
                await this.searchChannel()
            },

            formatMessage(order, data) {
                const month = this.utcToLocal(this.selectedMonth, 'MMMM')
                let templates = 'Terima kasih telah melakukan pembelanjaan produk Tuneeca. Terdapat <%= totalOrderQty%> pesanan kk yang belum dibayar:\n'
                
                data.content.forEach((e, index) => {
                    const amount = this.formatCurrency(e.amount)
                    templates += `\n${index + 1}. ${e.formalId} Rp ${amount} ${e.customerName} ${e.shippingAddress.city}`
                    
                    if(e.expiryTime) templates += ` *${this.formatDateExpiry(e.expiryTime)}*`
                })

                templates += `\n\nJika kk mengalami kendala dalam proses pembayaran, bisa disampaikan ke kami ya untuk kami bantu.\n\nselamat berbelanja`

                if(order.pctCancelOrder >= 10) {
                    templates += `\n\nPembatalan nota Anda dibulan (<%= month%>) <%= pctCancelOrder%> Persen. Yuk, jaga pembatalan nota tidak melebihi 15 persen supaya kuota launching tidak habis`
                }

                const str = template(templates)({
                    totalOrderQty: data.totalElements,
                    pctCancelOrder: order.pctCancelOrder,
                    month
                })

                return str
            },

            formatDateExpiry(date) {
                const currDate = moment();
                const expiryDate = moment(date);

                const diff = moment.duration(expiryDate.diff(currDate));
                // const weeks = Math.floor(diff.asWeeks());
                const days = Math.floor(diff.asDays());
                const hours = Math.floor(diff.asHours());
                const minutes = Math.floor(diff.asMinutes());

                const text = "Waktu"

                const modMinutes = minutes % 60
                const modHours = hours % 24

                if(minutes < 0) {
                    return "Sudah Kadaluarsa"
                } else if(minutes < 60) {
                    return `${text} ${minutes} menit`
                } else if(hours < 24) {
                    return `${text} ${hours} jam ${modMinutes} menit`
                } else {
                    return `${text} ${days} hari ${modHours} jam`
                } 
            },

            resetParams: function() {
                this.selectedMonth = String()
                this.month = String()
                this.selectedCustomerRoles = Array()
                this.size = 10
            },

            formatCurrency: function(price) {
                let val = (price / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },

            utcToLocal: function(date, format) {
                var utcLocal = moment(date).local().format(format);
                return utcLocal;
            },

            utcToLocalView: function(date, format) {
                var utcLocal = moment(date).format(format);
                return utcLocal;
            },

            localToUtc: function(date, format) {
                var utcLocal = moment(date).utc().format(format);
                return utcLocal;
            },
        },

        created: function () {
            this.$emit('update:layout', MainLayout);
        },

        mounted: function () {
            Scrollbar.initAll();
            if (!this.$helpers.isBlank(this.sessionInfo) && this.sessionInfo.isLoggedIn) {
                this.loadInitialData();
            }
            try {
                mainuisetup();
                commonpagesetup();
            } catch (err) {
                console.log(err);
            }
        },
        components: {
            // ErrorMessage,
            SuccessNotif,
        }
    }
</script>